<template>
  <div id="site" :class="{'menu-opened': menuOpened}">
    <header class="container app-header">
      <img src="@/assets/img/logo.svg" alt="logo" class="site-logo"/>
      <div class="try-nav-desktop">
        <a href="https://simple-recorder.com/onboarding/policy/">Privacy policy</a>
        <a href="https://simple-recorder.com/onboarding/terms/">Terms & condition</a>
        <a href="https://simple-recorder.com/onboarding/refund/">Money Back</a>
        <a href="https://simple-recorder.com/onboarding/support/">Support</a>
      </div>

      <span class="header-mobile-title">Call Recorder</span>

      <div v-if="menuOpened">
        <img src="@/assets/img/icons/icon-close.svg" class="close-btn" alt=""
             @click="$store.commit('appStore/setMenuOpened', false)">
      </div>
      <div v-else class="menu-icon" @click="$store.commit('appStore/setMenuOpened', true)">
        <img src="@/assets/img/icons/menu.svg" alt="">
      </div>
    </header>
    <div class="main-content">
      <slot/>
    </div>
    <app-menu class-name="bg-dark-blue"/>
    <footer class="purchase-footer d-flex justify-content-around bg-dark-blue m-auto py-3 mt-5">
<!--      <div class="company-label">-->
<!--        © 2021 APPITATE LLC-->
<!--      </div>-->
<!--      <div class="address">18401 Collins Ave STE 100-241, Sunny Isles Beach, Fl 33160</div>-->
      <div class="info">
        * operated by DEVURUM based on the agreement<br>
        Devurum Limited, HE 368090<br>
        Agiou Marona 19, Lakatamia, Nicosia, P.C. 2304,<br>
        Republic of Cyprus
      </div>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppMenu from './components/AppMenu';

export default {
  name: 'AppLayout',
  components: {
    AppMenu
  },
  beforeMount() {
    this.$store.commit('appStore/setMenuOpened', false);
  },
  computed: {
    ...mapGetters({
      menuOpened: 'appStore/menuOpened'
    })
  },
  watch: {
    menuOpened(val) {
      const body = document.body;
      val ? body.style.overflow = 'hidden' : body.style.overflow = 'auto';
      this.$store.commit('appStore/setMenuOpened', val);
    }
  }
};
</script>

<style lang="scss" scoped>
#site {
  min-height: 100vh;

  .header-mobile-title {
    display: none;
    font-weight: 700;
    color: #DD3D37 !important;
    font-size: 24px;
  }

  .close-btn {
    width: 25px;
    cursor: pointer;
  }

  .menu-icon {
    display: none;
  }

  .try-nav-desktop {
    display: flex;

    a {
      font-size: 17px;
      line-height: 25px;
      color: #000;
      margin: 0 8px;
      text-decoration: none;
      transition: .3s ease-out;

      &:hover {
        color: #007bff;
      }
    }
  }

  .app-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 70px;
    //padding: 0 50px;
    background: #FFF;
    z-index: 60;
    box-sizing: unset;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      transition: all linear 0.1s;
    }

    .site-logo {
      display: block;
      object-position: left center;
      object-fit: scale-down;
    }

    .page-title {
      font-family: Roboto;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #F7A826;
    }

    .btn-back {
      position: relative;
      width: 198px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      border: none;
      border-radius: 3px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;

      .arrow {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }

      .icon-close {
        display: none
      }

      &:hover .arrow {
        left: 15px;
      }
    }
  }

  .purchase-footer {
    max-width: 1440px;
    padding-bottom: 25px;
    color: #AEAEAE;
    font-size: 13px;
    text-align: center;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  #site {
    .header-mobile-title {
      display: flex;

    }

    .try-nav-desktop {
      display: none;
    }

    .menu-icon {
      display: flex;
    }
  }
  .purchase-footer {
    margin-bottom: 100px !important;
    flex-direction: column;

    .info {
      padding-bottom: 50px;
    }

    > div {
      text-align: center;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 325px) {
  .menu-wrapper {
    width: 100%;
  }
}
</style>