<template>
  <div
      class="menu-wrapper position-fixed top-0"
      :class="[ className]"
  >
    <!--    <img src="@/assets/img/icons/close.svg" class="close-btn" alt=""-->
    <!--         @click="$store.commit('appStore/setMenuOpened', false)">-->
    <div class="menu-items d-flex flex-column">
      <router-link v-for="item in menuItems" :key="item" :to="{name: item}">
        <span @click="onMenuItemClick(item)">{{ $t(`menu.${item}`) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    className: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    menuItems: ['terms', 'policy', 'refund', 'contact']
  }),
  computed: {
    ...mapGetters({
      menuOpened: 'appStore/menuOpened'
    })
  },
  methods: {
    onMenuItemClick(item) {
      this.amplitudeEvent('Menu clicked', {placement: 'start', option: item});
      this.$store.commit('appStore/setMenuOpened', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-opened {
  .menu-wrapper {
    transition: 2s;
    transform: translate(0,0);
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .menu-items {
      margin-top: 0;

      a {
        text-align: center;
      }
    }
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  right: 0;
  margin-top: 70px;
  width: 100%;
  padding: 25px;
  background: #fff !important;
  text-align: left;
  transition: 2s;
  transform: translate(-200%, -200%);
  opacity: 0;
  z-index: 100;

  .menu-items {
    margin-top: 0;
    padding: 0 5px;


    a {
      margin: 10px 0;
      font-size: 18px;
      color: #000;
      text-decoration: none;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 325px) {
  .menu-wrapper {
    width: 100%;
  }
}
</style>